<template>
  <article
    :class="{ out_of_stock: product && product.status == 'out_of_stock' }"
    class="in-product position-relative"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <template v-if="product && product.major_final_price">
      <DiscountTag :final-price="product.major_final_price" />
    </template>
    <span v-if="checkFreeShipMent()" id="shipment_free">
      ارسال رایگان
    </span>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :class="{ compareBtnWithFreeShipping: checkFreeShipMent() == true }"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: {
                name: `${
                  product.en_title
                    ? product.en_title.replaceAll(' ', '-') + '-'
                    : 'detail-'
                }${product.id}`,
              },
            }
          : ''
      "
      class="text-center"
    >
      <!--      <div class="recommended-products-img text-center" :style="!product ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)' : ''">-->
      <div class="recommended-products-img text-center">
        <figure>
          <img
            loading="lazy"
            v-if="product && mainImage"
            class="w-100 image-product"
            :src="getImage(mainImage, 'lg')"
            :alt="product.title"
          />
          <img
            loading="lazy"
            v-else
            class="skeleton-image w-100"
            :alt="product ? product.title : ''"
          />
        </figure>
        <span
          class="showQuantity"
          v-if="product && product.quantity && product.quantity < 5"
        >
          {{ `تنها ${product.quantity} عدد در انبار باقي مانده است` }}
        </span>
      </div>
    </router-link>
    <div
      v-if="product"
      class="text-center position-relative"
      id="productDescription"
    >
      <router-link
        :to="
          product
            ? {
                name: 'product.detail',
                params: {
                  name: `${
                    product.en_title
                      ? product.en_title.replaceAll(' ', '-') + '-'
                      : 'detail-'
                  }${product.id}`,
                },
              }
            : ''
        "
        class="text-center"
      >
        <div
          class="product-rate d-flex align-items-center justify-content-center"
        >
          <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
          <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
        </div>
        <h6
          :title="product.title"
          class="ProductName title-products  text-color-444 weight-bold fontsize-medium mb-0 mt-2 mt-sm-3"
        >
          {{ product.title.substr(0, 29) }}
          {{ product.title.length > 29 ? "..." : "" }}
        </h6>
      </router-link>
      <div class="d-flex justify-content-between align-items-center">
        <ProductFavorite
          :productId="product ? product.id : null"
          class="mr-2"
        />
        <router-link
          :to="
            product
              ? {
                  name: 'product.detail',
                  params: {
                    name: `${
                      product.en_title
                        ? product.en_title.replaceAll(' ', '-') + '-'
                        : 'detail-'
                    }${product.id}`,
                  },
                }
              : ''
          "
          class="text-center"
        >
          <div v-if="product.status == 'available'">
            <!-- اگر تخفیف نداشت -->
            <div v-if="discountAmount == null">
              <span class="text-color-themeRed fontsize-medium">
                {{ mainPrice | price }}
              </span>
              <span class="text-color-666 fontsize-small pr-1">تومان</span>
            </div>
            <!-- اگر تخفیف داشت -->
            <div v-if="discountAmount != null">
              <span class="text-color-themeRed fontsize-medium">
                {{ discountAmount | price }}
              </span>
              <span class="text-color-666 fontsize-small pr-1">تومان</span>
            </div>
            <!-- اگر تخفیف داشت روی قیمت اصلی خط بزن -->
            <div v-if="discountAmount != null">
              <div class="line-through text-color-999">
                <span class="text-color-999 fontsize-small">{{
                  mainPrice | price
                }}</span>
                <span class="text-color-999 fontsize-small pr-1">تومان</span>
              </div>
            </div>
          </div>
          <!-- اگر ناموجود یا پیشنویس بود -->
          <div v-else class="hide-by-hover ">
            <span
              v-if="product.status == 'out_of_stock'"
              class="text-color-themeRed fontsize14"
            >
              ناموجود
            </span>
            <span
              v-if="product.status == 'soon'"
              class="text-color-themeRed fontsize14"
            >
              به زودی
            </span>
          </div>

          <div v-else class="mt-3 px-4 px-sm-5">
            <b-skeleton />
            <b-skeleton class="mt-2" />
          </div>
        </router-link>

        <router-link
          :to="
            product
              ? {
                  name: 'product.detail',
                  params: {
                    name: `${
                      product.en_title
                        ? product.en_title.replaceAll(' ', '-') + '-'
                        : 'detail-'
                    }${product.id}`,
                  },
                }
              : ''
          "
          class="text-center"
        >
          <div class="text-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.4899 22.75H7.49993C5.77993 22.75 4.48994 22.29 3.68994 21.38C2.88994 20.47 2.57993 19.15 2.78993 17.44L3.68994 9.94C3.94994 7.73 4.50994 5.75 8.40994 5.75H15.6099C19.4999 5.75 20.0599 7.73 20.3299 9.94L21.2299 17.44C21.4299 19.15 21.1299 20.48 20.3299 21.38C19.4999 22.29 18.2199 22.75 16.4899 22.75ZM8.39993 7.25C5.51993 7.25 5.37993 8.38999 5.16993 10.11L4.26994 17.61C4.11994 18.88 4.29993 19.81 4.80993 20.38C5.31993 20.95 6.21993 21.24 7.49993 21.24H16.4899C17.7699 21.24 18.6699 20.95 19.1799 20.38C19.6899 19.81 19.8699 18.88 19.7199 17.61L18.8199 10.11C18.6099 8.37999 18.4799 7.25 15.5899 7.25H8.39993Z"
                fill="#A5A5A5"
              />
              <path
                d="M16 8.75C15.59 8.75 15.25 8.41 15.25 8V4.5C15.25 3.42 14.58 2.75 13.5 2.75H10.5C9.42 2.75 8.75 3.42 8.75 4.5V8C8.75 8.41 8.41 8.75 8 8.75C7.59 8.75 7.25 8.41 7.25 8V4.5C7.25 2.59 8.59 1.25 10.5 1.25H13.5C15.41 1.25 16.75 2.59 16.75 4.5V8C16.75 8.41 16.41 8.75 16 8.75Z"
                fill="#A5A5A5"
              />
              <path
                d="M20.41 17.78H8C7.59 17.78 7.25 17.44 7.25 17.03C7.25 16.62 7.59 16.28 8 16.28H20.41C20.82 16.28 21.16 16.62 21.16 17.03C21.16 17.44 20.82 17.78 20.41 17.78Z"
                fill="#A5A5A5"
              />
            </svg>
          </div>
        </router-link>
      </div>
    </div>

  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";
import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import ProductFavorite from "@/parts/Front/components/productDetail/ProductFavorite";

import DiscountTag from "./DiscountTag";
import Tick from "@/parts/Front/components/shared/Tick";
import moment from "moment";

export default {
  name: "Product",
  components: {
    Tick,
    DiscountTag,
    BFormRating,
    BSkeleton,
    moment,
    ProductFavorite,
    
  },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
    
  },
  data() {
    return {};
  },
  methods: {
    getImage,
    getProductInFlashes() {
      let products = [];
      this.homeData.flashes.forEach((flash) => {
        products.push(...flash.products);
      });
      return products;
    },
    checkFreeShipMent() {
      if (this.$route.path == "/") {
        if (
          this.product &&
          this.product.pivot &&
          this.product.pivot.shipment_free == 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.homeData != null) {
          if (this.homeData.flashes.length) {
            let res = false;

            let flashesProduct = this.getProductInFlashes();
            console.log(flashesProduct);
            if (flashesProduct.length) {
              flashesProduct.forEach((fp) => {
                console.log(fp.id);
                console.log(this.product.id);
                if (fp.id == this.product.id) {
                  res = true;
                  console.log("inja");
                }
              });
            }
            return res;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    getExpireDate() {
      let now = this.$store.getters["front/getHomeData"]
        ? this.$store.getters["front/getHomeData"].now
        : new Date();
      let until = this.product.discount_until;
      let between = Date.parse(until) - Date.parse(now);
      console.log(Date.parse(until));
      console.log(Date.parse(now));
      console.log(between);
      return this.product.discount_until;
    },
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.major_final_price.sell_price : null;
    },
    discountAmount() {
      return this.product
        ? this.product.major_final_price.sell_price_discount
        : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  created() {},
};
</script>

<style scoped>
.recommended-discount {
  transform: rotate(-90deg);
  transform-origin: bottom right;
  border-radius: 20px;
}
.title-products {
  border-bottom: 1px dashed rgb(206, 206, 206);
  padding: 2px 0 8px 0;
  margin-bottom: 10px !important;
  font-weight: 900;
  margin-top: 2px !important;
}
#productDescription {
  padding: 16px;
}
.recommended-products-img {
  overflow: hidden;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.recommended-products-img figure {
  overflow: hidden;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.in-product {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #fff;
  transition: all 0.5s;
}
.image-product {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: all 0.5s;
}
.in-product:hover.in-product .image-product {
  transform: scale(1.2);
  transition: all 0.5s;
}
.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}

.compare-btn {
  z-index: 2;
  position: absolute;
  right: 3px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}
.compare-btn.compareBtnWithFreeShipping {
  top: 30px;
}
.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}

.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}

.py-25 {
  padding: 14px 0;
}
span#shipment_free {
  position: absolute;
  left: 0;
  background: green;
  z-index: 10000;
  color: white;
  padding: 5px 10px;
  top: 0;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 13px;
}
/* .flip-clock {
  direction: ltr;
}
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  background-color: #f5c8dd !important;
  color: #000 !important;
}
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
  background-color: #a9939d !important;
  color: #000 !important;
} */

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}
span.showQuantity {
  position: absolute;
  left: 0;
  bottom: 24px;
  width: 100%;
  background: #c40050;
  color: white;
}
.out_of_stock {
  opacity: 0.5;
}

</style>
